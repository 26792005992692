import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Footer from "../components/footer"
import About from "../components/about"
import Hero from "../components/hero"
import News from "../components/news"
import { StaticImage } from "gatsby-plugin-image"

export default class IndexPage extends React.Component {

  render() {

    return (

      <Layout>

        <Seo title="SPCO" lang="en" />

        <Hero />

        <About />

        {/* <div className="text-center">

          <a href="https://www.adipec.com/exhibitordetails?exhibitorid=6727" target="_blank">

            <StaticImage
              src="../images/expo.jpg"
              formats={["auto", "webp", "avif"]}
              alt=""
              className="img-fluid"
            />

          </a>

        </div> */}

        <News />

        <Footer />

      </Layout>

    )

  }

}